<template>
	<b-row>
		<b-col sm="12" md="4" lg="4" xl="4">
			<b-form-group>
				<template slot="label">
					<label for="email">{{ FormMSG(57, 'Transportation mean') }}</label>
					<span class="required" v-if="mandatoryVehiculeTransportationMean"> *</span>
				</template>
				<b-form-select v-model="$v.transportationMeanType.$model" :disabled="disableForm" @change="handleOptionSelected($event)">
					<b-form-select-option :value="null">{{ FormMSG(17, 'Select a type') }}</b-form-select-option>
					<b-form-select-option-group v-if="!hideBoatType" :label="FormMSG(39, 'Boat')">
						<b-form-select-option v-for="(boat, index) in boatOptions" :value="boat.value" :key="index + '_boat'">{{
							boat.text
						}}</b-form-select-option>
					</b-form-select-option-group>
					<b-form-select-option-group v-if="!hideBusType" :label="FormMSG(40, 'Bus')">
						<b-form-select-option v-for="(bus, index) in busOptions" :value="bus.value" :key="index + '_bus'">{{ bus.text }}</b-form-select-option>
					</b-form-select-option-group>
					<b-form-select-option-group v-if="!hideCarType" :label="FormMSG(41, 'Car')">
						<b-form-select-option v-for="(car, index) in carOptions" :value="car.value" :key="index + '_car'">{{ car.text }}</b-form-select-option>
					</b-form-select-option-group>
					<b-form-select-option-group v-if="!hideFreightType" :label="FormMSG(69, 'Freight')">
						<b-form-select-option v-for="(freight, index) in freightOptions" :value="freight.value" :key="index + '_freight'">{{
							freight.text
						}}</b-form-select-option>
					</b-form-select-option-group>
					<b-form-select-option-group v-if="!hideOtherType" :label="FormMSG(42, 'Other')">
						<b-form-select-option v-for="(other, index) in otherOptions" :value="other.value" :key="index + '_other'">{{
							other.text
						}}</b-form-select-option>
					</b-form-select-option-group>
					<b-form-select-option-group v-if="!hideSpecialVehicles" :label="FormMSG(1542, 'Special vehicles')">
						<b-form-select-option v-for="(spVehicle, index) in specialVehicleOptions" :value="spVehicle.value" :key="index + '_spVehicle'">{{
							spVehicle.text
						}}</b-form-select-option>
					</b-form-select-option-group>
				</b-form-select>
				<div v-if="$v.transportationMeanType.$error" class="invalid-feedback">
					{{ FormMSG(53, 'Please, select a type') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="showDetail" sm="12" md="4" lg="4" xl="4">
			<b-form-group :label="FormMSG(58, 'Detail')">
				<v-select
					v-model="$v.transportationDetail.$model"
					:options="detailsOptions"
					label="text"
					:reduce="(option) => option.value"
					:placeholder="FormMSG(85, 'Please select')"
					:clearable="false"
					:class="{ 'is-invalid': $v.transportationDetail.$error }"
					:disabled="disableForm || transportationMeanType === null"
					@option:selected="handleDetailOptionSelected"
				>
					<template #selected-option="{ text }">
						<div>
							{{ sliceAWord(text) }}
						</div>
					</template>
				</v-select>
				<div v-if="$v.transportationDetail.$error" class="invalid-feedback">
					{{ FormMSG(53, 'Please, select a type') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="showAdditionalDetail" sm="12" md="4" lg="4" xl="4">
			<b-form-group :label="FormMSG(59, 'Additional detail')">
				<v-select
					v-model="$v.transportationAdditionalDetail.$model"
					:options="additionalDetailsOptions"
					label="text"
					:reduce="(option) => option.value"
					:placeholder="FormMSG(85, 'Please select')"
					:clearable="false"
					:class="{ 'is-invalid': $v.transportationDetail.$error }"
					:disabled="disableForm || transportationDetail === null"
				/>
				<div v-if="$v.transportationAdditionalDetail.$error" class="invalid-feedback">
					{{ FormMSG(53, 'Please, select a type') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="showHireRentalOption" sm="12" md="4" lg="4" xl="4">
			<b-form-group>
				<label v-if="$screen.width > 576">&nbsp;</label>
				<b-form-radio-group
					v-model="$v.transportationAdditionalDetail.$model"
					:options="statusOptions"
					name="radio-options"
					plain
					:disabled="disableForm"
				/>
			</b-form-group>
		</b-col>
		<b-col v-if="showSpecialEquipmentSelector" sm="12" md="4" lg="4" xl="4">
			<b-form-group :label="labelSpecialEquipment">
				<v-select
					v-model="$v.transportationSpecialEquipment.$model"
					label="name"
					:reduce="(option) => +option.id"
					:options="equipmentOptions"
					:placeholder="FormMSG(1587, 'Please select ...')"
					:class="{ 'is-invalid': $v.transportationSpecialEquipment.$error && getStateValidationField('transportationSpecialEquipment') }"
				/>
				<div v-if="$v.transportationSpecialEquipment.$error" class="invalid-feedback">
					{{ errorMsgSpecialEquipmetSelector }}
				</div>
			</b-form-group>
		</b-col>
	</b-row>
</template>

<script>
import { noTilde } from '~utils';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { TRANSPORTATION_MEAN_MENU, TRANSPORTATION_MEAN_DETAIL_MENU } from '@/shared/constants';
import { SPE_VEHICLE_GENERATOR_TRUCK_TYPE, SPE_VEHICLE_PRODUCTION_TRUCK_TYPE, SPE_VEHICLE_TRAILER_TYPE } from '@/shared/constants';
import { getSpecialEquipmentList } from '@/cruds/special-equipment.crud';
import { SPE_GENERATOR_TYPE, SPE_PRODUCTION_ROOMS_TYPE, SPE_TRAILER_TYPE } from '../../../shared/constants';

export default {
	name: 'TransportTypeSelector',
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		hideBoatType: { type: Boolean, default: false, required: false },
		hideBusType: { type: Boolean, default: false, required: false },
		hideCarType: { type: Boolean, default: false, required: false },
		hideOtherType: { type: Boolean, default: false, required: false },
		hideFreightType: { type: Boolean, default: false, required: false },
		dataType: { type: Number, default: null, required: true },
		dataDetail: { type: Number, default: null, required: true },
		dataAdditionalDetail: { type: Number, default: null, required: true },
		dataSpecialEquipment: { type: Number, default: null, required: false },
		disableForm: { type: Boolean, default: false, required: false },
		stillDisplay: { type: Boolean, default: false, required: false },
		hideMenuesNotTwoWheels: { type: Boolean, default: false, required: false },
		hideSpecialVehicles: { type: Boolean, default: false, required: false },
		mandatoryVehiculeTransportationMean: { type: Boolean, default: false, required: false }
	},
	data() {
		return {
			isFreighter: false,
			transportationMeanType: null,
			transportationDetail: null,
			transportationAdditionalDetail: null,
			transportationSpecialEquipment: null,
			detailsOptions: [],
			additionalDetailsOptions: [],
			equipmentOptions: []
		};
	},
	watch: {
		dataType: {
			async handler(newVal) {
				this.$nextTick(async () => {
					if (newVal > 0) {
						this.transportationMeanType = newVal;
						await this.handleOptionSelected(newVal, false);
					} else {
						this.transportationMeanType = null;
					}
				});
			},
			deep: true,
			immediate: true
		},
		dataDetail: {
			handler(newVal) {
				this.$nextTick(() => {
					if (newVal > 0) {
						this.transportationDetail = newVal;
						this.handleDetailOptionSelected({ value: newVal }, false);
					} else {
						this.transportationDetail = null;
					}
				});
			},
			deep: true,
			immediate: true
		},
		dataAdditionalDetail: {
			handler(newVal) {
				this.$nextTick(() => {
					this.transportationAdditionalDetail = newVal === 0 ? null : newVal;
				});
			},
			deep: true,
			immediate: true
		},
		dataSpecialEquipment: {
			handler(newVal) {
				this.$nextTick(() => {
					this.transportationSpecialEquipment = newVal === 0 ? null : newVal;
				});
			},
			deep: true,
			immediate: true
		},
		transportationMeanType: {
			handler(newVal) {
				this.isItFreighter(newVal);
				this.emitEvent();
			},
			deep: true
		},
		transportationDetail: {
			handler(newVal) {
				this.emitEvent();
			},
			deep: true
		},
		transportationAdditionalDetail: {
			handler(newVal) {
				this.emitEvent();
			},
			deep: true
		},
		transportationSpecialEquipment: {
			handler(newVal) {
				this.emitEvent();
			},
			deep: true
		}
	},
	validations() {
		return {
			transportationMeanType: { required },
			transportationDetail: { required: this.showDetail ? required : {} },
			transportationAdditionalDetail: { required: this.showAdditionalDetail ? required : {} },
			transportationSpecialEquipment: {
				required: this.menuSpecVehicle.findIndex((option) => option.value === this.transportationMeanType) > -1 ? required : {}
			}
		};
	},
	computed: {
		menuSpecVehicle() {
			return this.FormMenu(TRANSPORTATION_MEAN_MENU.SPECIAL_VEHICLE);
		},
		showSpecialEquipmentSelector() {
			return (
				this.transportationMeanType === SPE_VEHICLE_GENERATOR_TRUCK_TYPE ||
				this.transportationMeanType === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE ||
				this.transportationMeanType === SPE_VEHICLE_TRAILER_TYPE
			);
		},
		errorMsgSpecialEquipmetSelector() {
			if (this.transportationMeanType === SPE_VEHICLE_GENERATOR_TRUCK_TYPE) {
				return this.FormMSG(697, 'Please select a generator');
			} else if (this.transportationMeanType === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE) {
				return this.FormMSG(698, 'Please select a production truck');
			} else if (this.transportationMeanType === SPE_VEHICLE_TRAILER_TYPE) {
				return this.FormMSG(699, 'Please select a trailer');
			}
		},
		labelSpecialEquipment() {
			if (this.transportationMeanType === SPE_VEHICLE_GENERATOR_TRUCK_TYPE) {
				return this.FormMSG(851, 'Generator');
			} else if (this.transportationMeanType === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE) {
				return this.FormMSG(852, 'Production truck');
			} else if (this.transportationMeanType === SPE_VEHICLE_TRAILER_TYPE) {
				return this.FormMSG(853, 'Trailer');
			}
		},
		showDetail() {
			if (this.stillDisplay && this.transportationMeanType === null) {
				return true;
			}
			const menues = [6, 7, 8, 12, 13, 15, 16];
			return this.transportationMeanType !== null && menues.includes(this.transportationMeanType);
		},
		showAdditionalDetail() {
			if (this.stillDisplay && this.transportationDetail === null && this.transportationMeanType === null) {
				return true;
			}
			const menues = [5, 14, 15];

			return this.transportationMeanType !== null && this.transportationDetail !== null && menues.includes(this.transportationDetail);
		},
		showHireRentalOption() {
			const menues = [1, 2, 3, 4];
			return !_.isNil(this.transportationMeanType) && !_.isNil(this.transportationDetail) && menues.includes(this.transportationDetail);
		},
		statusOptions() {
			return this.FormMenu(TRANSPORTATION_MEAN_DETAIL_MENU.HIRE_RENTAL);
		},
		freightOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.FREIGHT);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && ![17, 18, 19].includes(menu.value);
					}
				})
			];
		},
		boatOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.BOAT);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [...menus.filter((menu) => menu.text !== 'not found')];
		},
		busOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.BUS);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && menu.value !== 5;
					}
				})
			];
		},
		carOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.CAR);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [...menus.filter((menu) => menu.text !== 'not found')];
		},
		otherOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.OTHER);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && ![9, 10, 12, 14, 15].includes(menu.value);
					}
				})
			];
		},
		specialVehicleOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.SPECIAL_VEHICLE);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [...menus];
		}
	},
	methods: {
		isItFreighter(val) {
			const freightMenues = [17, 18, 19, 20, 21, 25];
			if (freightMenues.includes(val)) {
				this.isFreighter = true;
			}
		},
		async getEquipmentOptions() {
			if (this.transportationMeanType === SPE_VEHICLE_GENERATOR_TRUCK_TYPE) {
				this.equipmentOptions = await getSpecialEquipmentList({ type: SPE_GENERATOR_TYPE, arrayType: [{ type: 3 }] });
			} else if (this.transportationMeanType === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE) {
				this.equipmentOptions = await getSpecialEquipmentList({
					type: SPE_PRODUCTION_ROOMS_TYPE,
					measurementOption: false,
					arrayType: [{ type: 3 }, { type: 4 }, { type: 5 }]
				});
			} else if (this.transportationMeanType === SPE_VEHICLE_TRAILER_TYPE) {
				this.equipmentOptions = await getSpecialEquipmentList({ type: SPE_TRAILER_TYPE });
			}
		},
		emitEvent() {
			const resultTransportationDetail = this.transportationDetail === null ? 0 : this.transportationDetail;

			let resultTransportationAdditionalDetail = 0;
			if (!_.isNil(this.transportationAdditionalDetail) && !_.isNil(this.transportationAdditionalDetail.value)) {
				resultTransportationAdditionalDetail = this.transportationAdditionalDetail.value === null ? 0 : this.transportationAdditionalDetail.value;
			} else {
				resultTransportationAdditionalDetail = this.transportationAdditionalDetail === null ? 0 : this.transportationAdditionalDetail;
			}
			this.$emit('transportation-type-selector:change', {
				type: this.transportationMeanType === null ? 0 : this.transportationMeanType,
				detail: resultTransportationDetail,
				additionalDetail: resultTransportationAdditionalDetail,
				specialEquipment: this.transportationSpecialEquipment === null ? 0 : this.transportationSpecialEquipment,
				isFreighter: this.isFreighter
			});

			this.$emit('transportation-type-selector:invalid', this.isInvalid());
		},
		sliceAWord(value) {
			if (value.length > 20) {
				return value.substring(0, 20) + ' ...';
			}

			return value;
		},
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleDetailOptionSelected(payload, init = true) {
			if ([1, 2, 3, 4].includes(payload.value)) {
				if (init) {
					this.transportationAdditionalDetail = 7;
				}
			}
			if ([5, 6, 7, 8].includes(payload.value)) {
				if (init) {
					this.transportationAdditionalDetail = null;
				}

				this.getAdditionalsDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE_TYPE);
			} else if ([14, 15].includes(payload.value)) {
				if (init) {
					this.transportationAdditionalDetail = null;
				}

				this.getAdditionalsDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.CAR);
			}
		},
		getAdditionalsDetailsOptions(menuNumber) {
			let menus = this.FormMenu(menuNumber);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			this.additionalDetailsOptions = menus;
		},
		async handleOptionSelected(payload, init = true) {
			const carMenues = [6, 7, 8];

			if (carMenues.includes(payload)) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
					this.transportationSpecialEquipment = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.CAR); // MENU FOR CAR
			} else if (payload === 12) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
					this.transportationSpecialEquipment = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE); // MENU FOR PLANE
			} else if (payload === 13) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
					this.transportationSpecialEquipment = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.SCOOTER); // MENU FOR SCOOTER
			} else if (payload === 15) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
					this.transportationSpecialEquipment = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.TRAIN); // MENU FOR TRAIN
			} else if (payload === 16) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
					this.transportationSpecialEquipment = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.VAN); // MENU FOR VAN
			} else if (payload === SPE_VEHICLE_GENERATOR_TRUCK_TYPE || payload === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE || payload === SPE_VEHICLE_TRAILER_TYPE) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
					this.transportationSpecialEquipment = null;
				}

				await this.getEquipmentOptions();
			} else {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}
			}
		},
		getDetailsOptions(menuNumber) {
			let menus = this.FormMenu(menuNumber);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			this.detailsOptions = menus;
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		}
	},
	mounted() {
		this.$emit('transportation-type-selector:invalid', this.isInvalid());
	}
};
</script>

<style lang="scss" scoped>
optgroup {
	background-color: #e7e2e2;
	color: gray;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;

	option {
		color: #06263e;
		font-size: 12px;
		font-weight: 700;
	}
}

.required {
	color: red;
}
</style>

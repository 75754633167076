var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
        [
          _c(
            "b-form-group",
            [
              _c("template", { slot: "label" }, [
                _c("label", { attrs: { for: "email" } }, [
                  _vm._v(_vm._s(_vm.FormMSG(57, "Transportation mean"))),
                ]),
                _vm.mandatoryVehiculeTransportationMean
                  ? _c("span", { staticClass: "required" }, [_vm._v(" *")])
                  : _vm._e(),
              ]),
              _c(
                "b-form-select",
                {
                  attrs: { disabled: _vm.disableForm },
                  on: {
                    change: function ($event) {
                      return _vm.handleOptionSelected($event)
                    },
                  },
                  model: {
                    value: _vm.$v.transportationMeanType.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.transportationMeanType, "$model", $$v)
                    },
                    expression: "$v.transportationMeanType.$model",
                  },
                },
                [
                  _c("b-form-select-option", { attrs: { value: null } }, [
                    _vm._v(_vm._s(_vm.FormMSG(17, "Select a type"))),
                  ]),
                  !_vm.hideBoatType
                    ? _c(
                        "b-form-select-option-group",
                        { attrs: { label: _vm.FormMSG(39, "Boat") } },
                        _vm._l(_vm.boatOptions, function (boat, index) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: index + "_boat",
                              attrs: { value: boat.value },
                            },
                            [_vm._v(_vm._s(boat.text))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  !_vm.hideBusType
                    ? _c(
                        "b-form-select-option-group",
                        { attrs: { label: _vm.FormMSG(40, "Bus") } },
                        _vm._l(_vm.busOptions, function (bus, index) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: index + "_bus",
                              attrs: { value: bus.value },
                            },
                            [_vm._v(_vm._s(bus.text))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  !_vm.hideCarType
                    ? _c(
                        "b-form-select-option-group",
                        { attrs: { label: _vm.FormMSG(41, "Car") } },
                        _vm._l(_vm.carOptions, function (car, index) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: index + "_car",
                              attrs: { value: car.value },
                            },
                            [_vm._v(_vm._s(car.text))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  !_vm.hideFreightType
                    ? _c(
                        "b-form-select-option-group",
                        { attrs: { label: _vm.FormMSG(69, "Freight") } },
                        _vm._l(_vm.freightOptions, function (freight, index) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: index + "_freight",
                              attrs: { value: freight.value },
                            },
                            [_vm._v(_vm._s(freight.text))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  !_vm.hideOtherType
                    ? _c(
                        "b-form-select-option-group",
                        { attrs: { label: _vm.FormMSG(42, "Other") } },
                        _vm._l(_vm.otherOptions, function (other, index) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: index + "_other",
                              attrs: { value: other.value },
                            },
                            [_vm._v(_vm._s(other.text))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  !_vm.hideSpecialVehicles
                    ? _c(
                        "b-form-select-option-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(1542, "Special vehicles"),
                          },
                        },
                        _vm._l(
                          _vm.specialVehicleOptions,
                          function (spVehicle, index) {
                            return _c(
                              "b-form-select-option",
                              {
                                key: index + "_spVehicle",
                                attrs: { value: spVehicle.value },
                              },
                              [_vm._v(_vm._s(spVehicle.text))]
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.$v.transportationMeanType.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(53, "Please, select a type")) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.showDetail
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(58, "Detail") } },
                [
                  _c("v-select", {
                    class: { "is-invalid": _vm.$v.transportationDetail.$error },
                    attrs: {
                      options: _vm.detailsOptions,
                      label: "text",
                      reduce: (option) => option.value,
                      placeholder: _vm.FormMSG(85, "Please select"),
                      clearable: false,
                      disabled:
                        _vm.disableForm || _vm.transportationMeanType === null,
                    },
                    on: { "option:selected": _vm.handleDetailOptionSelected },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function ({ text }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.sliceAWord(text)) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2153785479
                    ),
                    model: {
                      value: _vm.$v.transportationDetail.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.transportationDetail, "$model", $$v)
                      },
                      expression: "$v.transportationDetail.$model",
                    },
                  }),
                  _vm.$v.transportationDetail.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.FormMSG(53, "Please, select a type")) +
                            "\n\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showAdditionalDetail
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(59, "Additional detail") } },
                [
                  _c("v-select", {
                    class: { "is-invalid": _vm.$v.transportationDetail.$error },
                    attrs: {
                      options: _vm.additionalDetailsOptions,
                      label: "text",
                      reduce: (option) => option.value,
                      placeholder: _vm.FormMSG(85, "Please select"),
                      clearable: false,
                      disabled:
                        _vm.disableForm || _vm.transportationDetail === null,
                    },
                    model: {
                      value: _vm.$v.transportationAdditionalDetail.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.transportationAdditionalDetail,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.transportationAdditionalDetail.$model",
                    },
                  }),
                  _vm.$v.transportationAdditionalDetail.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.FormMSG(53, "Please, select a type")) +
                            "\n\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showHireRentalOption
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                [
                  _vm.$screen.width > 576
                    ? _c("label", [_vm._v(" ")])
                    : _vm._e(),
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.statusOptions,
                      name: "radio-options",
                      plain: "",
                      disabled: _vm.disableForm,
                    },
                    model: {
                      value: _vm.$v.transportationAdditionalDetail.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.transportationAdditionalDetail,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.transportationAdditionalDetail.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSpecialEquipmentSelector
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.labelSpecialEquipment } },
                [
                  _c("v-select", {
                    class: {
                      "is-invalid":
                        _vm.$v.transportationSpecialEquipment.$error &&
                        _vm.getStateValidationField(
                          "transportationSpecialEquipment"
                        ),
                    },
                    attrs: {
                      label: "name",
                      reduce: (option) => +option.id,
                      options: _vm.equipmentOptions,
                      placeholder: _vm.FormMSG(1587, "Please select ..."),
                    },
                    model: {
                      value: _vm.$v.transportationSpecialEquipment.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.transportationSpecialEquipment,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.transportationSpecialEquipment.$model",
                    },
                  }),
                  _vm.$v.transportationSpecialEquipment.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.errorMsgSpecialEquipmetSelector) +
                            "\n\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }